import React from 'react';
import './OurServices.css';
import Services1 from '../../../assets/ServicePage/Creative Design.mp4';
import Services2 from '../../../assets/ServicePage/UI-UX Design.mp4';
import Services3 from '../../../assets/ServicePage/FullStack Dev.mp4';
import Services4 from '../../../assets/ServicePage/Digital Marketing.mp4';
import Services5 from '../../../assets/ServicePage/Marketing Analysis.mp4';
import { useNavigate } from 'react-router-dom';

const servicesData = [
    { videoSource: Services1, heading: 'Creative Design', text: 'Our creative design services turn your ideas into compelling digital experiences. Using innovative visuals and storytelling, we craft engaging narratives that resonate with your audience.' },
    { videoSource: Services2, heading: 'UI/UX Design', text: 'Our UI/UX design redefines digital interaction by merging aesthetics with functionality. We create intuitive interfaces and immersive experiences that drive user engagement.' },
    { videoSource: Services3, heading: 'Full Stack Development', text: 'We offer full-stack development services that combine strong backend capabilities with dynamic front-end interactions, delivering seamless applications tailored to your needs.' },
    { videoSource: Services4, heading: 'Digital Marketing', text: 'Our digital marketing services enhance your brand\'s online presence through strategic creativity, technical expertise, and data-driven insights, boosting visibility and engagement.' },
    { videoSource: Services5, heading: 'Marketing Analysis', text: 'We provide market analysis that delivers actionable insights. By studying market trends, consumer behavior, and competitors, we help guide your strategic business decisions.' }
]

function OurServices() {
    const navigate = useNavigate();
    return (
        <div className='our-services-section'>
            <div className='service-text-btn-sec'>
                <h1 className='services-heading' style={{ marginTop: "2.2rem" }}>Our Services</h1>
                <div>
                    <button onClick={() => { navigate('/services') }} className='home-service-cta'>View More</button>
                </div>
            </div>
            <p className='service-team-text service-text-our-sec'>Allow us to provide an external viewpoint that elevates your ideas, designs, and implementations from good to extraordinary. Relax knowing that we'll guide you not only to your destination but also beyond. We address any support issues and handle additional development that may arise, akin to a friendly presence in your closet – subtle yet undeniably tangible.</p>

            {servicesData.map((service, index) => (
                <div key={index}>
                    <hr className='services-hr' />
                    <div className='services-container'>
                        <div className='text-container'>
                            <h2 className='service-team-heading'>{service.heading}</h2>
                            <div className='video-container2'>
                                <video className='service-video2' loop playsInline disablePictureInPicture preload="metadata" autoPlay muted>
                                    <source src={service.videoSource} type='video/mp4' />
                                    <meta itemprop="name" content={"vishesh" + service.heading + ".png"} />
                                </video>
                            </div>
                            <p className='service-team-text'>{service.text}</p>
                        </div>
                        <div className='video-container1'>
                            <video className='service-video' loop playsInline disablePictureInPicture preload="metadata" autoPlay muted>
                                <source src={service.videoSource} type='video/mp4' />
                                <meta itemprop="name" content={"vishesh" + service.heading + ".png"} />
                            </video>
                        </div>
                    </div>
                </div>
            ))}
            <hr className='services-hr' />

            <button onClick={() => { navigate('/services') }} className='home-service-cta1'>View More</button>

        </div>
    );
}

export default OurServices;
