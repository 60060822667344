import React from 'react';
import { Container, Row } from 'react-bootstrap';
import "./WorkPageHeader.css"

function WorkPageHeader(props) {
    return (
        <Container fluid className='WorkPageHeader-container'>
            <Row >
                <div className='WorkPageHeader-container-row-col-1'>
                    <h4 className='services-heading ' style={{ textAlign: 'left' }}>Our Approach to Work</h4>
                </div>
                <div className='WorkPageHeader-container-row-col-2'>
                    <h6 className='home-project-text ServicesPage-Header-Sub' style={{margin: 0, paddingTop: "3%" }}>At Vishesh Country Cache, we proudly embrace a unique aesthetic philosophy in every project. We are committed to forming meaningful partnerships with clients who align with our core values.</h6>
                </div>
            </Row>
        </Container>
    );
}

export default WorkPageHeader;