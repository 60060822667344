import React, { useRef, useState } from 'react';
import "./ProjectsScrolling.css"
import { Container, Row } from 'react-bootstrap';
import Slider from "react-slick";
import CreativeDesignImage1 from '../../../assets/WorkPage/Brochure Mockup.avif';
import CreativeDesignImage2 from '../../../assets/WorkPage/Business Card Mockups.avif';
import CreativeDesignImage3 from '../../../assets/WorkPage/Business Card Mockups-2.avif';
import CreativeDesignImage4 from '../../../assets/WorkPage/Logo-Mockup.avif';
import CreativeDesignImage5 from '../../../assets/WorkPage/Logo-Ultimates-Mockup.avif';
import CreativeDesignImage6 from '../../../assets/WorkPage/Stand-Up-Banner.avif';
import CreativeDesignImage7 from '../../../assets/WorkPage/Website-Mockup.avif';

import DevelopmentProjectsImage1 from '../../../assets/WorkPage/Innowise.avif';
import DevelopmentProjectsImage2 from '../../../assets/WorkPage/KMV.avif';
import DevelopmentProjectsImage3 from '../../../assets/WorkPage/Quiz_01.avif';

import AnalysisProjectsImage1 from '../../../assets/WorkPage/CRM Dashboard.avif';
import AnalysisProjectsImage2 from '../../../assets/WorkPage/CRM_02.avif';
import AnalysisProjectsImage3 from '../../../assets/WorkPage/Fashion Industry.avif';
import AnalysisProjectsImage4 from '../../../assets/WorkPage/Jajimalli Portfoilo.avif';
import AnalysisProjectsImage5 from '../../../assets/WorkPage/Restaurants-Portfolio.avif';

import MarketingProjectsImage1 from '../../../assets/WorkPage/02_1.avif';
import MarketingProjectsImage2 from '../../../assets/WorkPage/Happy Client.avif';
import MarketingProjectsImage3 from '../../../assets/WorkPage/Jajimalli-Posters_01.avif';
import MarketingProjectsImage4 from '../../../assets/WorkPage/Jajimalli.avif';
import MarketingProjectsImage5 from '../../../assets/WorkPage/News-Letters.avif';

import UI_UxProjectsImage1 from '../../../assets/WorkPage/KMV_02.avif';
import UI_UxProjectsImage3 from '../../../assets/WorkPage/Sri-Chaitanaya.avif';
import UI_UxProjectsImage4 from '../../../assets/WorkPage/Ultimates.avif';
const CreativeDesignProjects = [
    { images: [CreativeDesignImage4], ProjectName: "Nimmagadda", ProjectType: "Creative Design / Branding / Logo", 
    description: "Crafted a distinctive design solution to enhance the hospital's identity and resonate with its audience." },
    { images: [CreativeDesignImage5], ProjectName: "Ultimates Roofing", ProjectType: "Creative Design / Branding / Logo", 
    description: "We created a unique design that boosted the company’s visibility and helped it connect with its audience." },
    { images: [CreativeDesignImage3], ProjectName: "Sri Chaitanya Ortho & Eye", ProjectType: "Creative Design / Branding / Business Card", 
    description: "Providing a compelling design solution for Sri Chaitanya Hospital to establish a distinct identity and foster strong community connections." },
    { images: [CreativeDesignImage6], ProjectName: "Stand Up Banner", ProjectType: "Creative Design / Banner Ads", 
    description: "Delivering high-quality stand-up banners to captivate attention and effectively convey the message or brand of clients." },
    { images: [CreativeDesignImage2], ProjectName: "Business Cards", ProjectType: "Creative Design / Branding / Business Cards", 
    description: "Crafting creative business cards tailored to elevate brand identity and leave a lasting impression." },
    { images: [CreativeDesignImage1], ProjectName: "Brochure", ProjectType: "Creative Design / Brochure", 
    description: "Producing captivating brochures to showcase offerings and engage the target audience effectively." },
    { images: [CreativeDesignImage7], ProjectName: "Instagram Post", ProjectType: "Creative Design / Social Media Ads", 
    description: "Crafting compelling social media ads and captivating Instagram posts to enhance online presence and attract target audience." },
    { images: [MarketingProjectsImage2], ProjectName: "Instagram Poster", ProjectType: "Creative Design / Social Media Ads / Poster", 
    description: "Creating eye-catching posters to reflect brand identity and effectively communicate the message." },
    { images: [MarketingProjectsImage4], ProjectName: "Jajimalli", ProjectType: "Creative Design / Banner Ads", 
    description: "Provided a range of creative design and branding services to strengthen their market position. " }
]

const AnalysisProjects = [
    { images: [AnalysisProjectsImage1], ProjectName: "Agency Dashboard", ProjectType: "Marketing Analysis / Custom Dashboard", 
    description: "Delivered a dashboard for optimizing marketing strategies and driving business growth." },
    { images: [AnalysisProjectsImage2], ProjectName: "CRM Dashboard", ProjectType: "Marketing Analysis / Custom Dashboard", 
    description: "We developed a dashboard that gives clients real-time customer data, making sales processes smoother and improving customer relationships." },
    { images: [AnalysisProjectsImage3], ProjectName: "Fashion Industry Presentation", ProjectType: "Marketing Analysis / Presentation", 
    description: "Curating and delivering a captivating presentation for a multinational corporation event in the fashion industry, showcasing innovative strategies and trends." },
    { images: [AnalysisProjectsImage4], ProjectName: "Jajimalli Dashboard", ProjectType: "Marketing Analysis / Custom Dashboard", 
    description: "Developed a comprehensive dashboard offering insights into client profitability and sales performance." },
    { images: [AnalysisProjectsImage5], ProjectName: "Restaurant Portfoilo", ProjectType: "Marketing Analysis / Presentation", 
    description: "Partnered to enhance offerings and optimize customer experiences using data-driven insights and innovative strategies." },
]
const MarketingProjects = [
    { images: [MarketingProjectsImage1], ProjectName: "Newsletter", ProjectType: "Digital Marketing / E-mail Marketing / Newsletter", 
    description: "Crafting newsletters, executing email campaigns, and implementing strategies to enhance brand visibility and engagement through email marketing efforts" },
    { images: [MarketingProjectsImage3], ProjectName: "Jajimalli", ProjectType: "Digital Marketing / Social Media Marketing", 
    description: "Providing comprehensive digital marketing services, including newsletters, emails, and social media efforts, to drive brand awareness and customer engagement." },
    { images: [MarketingProjectsImage5], ProjectName: "Newsletter", ProjectType: "Digital Marketing / E-mail Marketing / Newsletter", 
    description: "Delivering personalized newsletters and effective email campaigns to enhance brand recognition and online presence through targeted marketing efforts." }
]
const DevelopmentProjects = [
    { images: [UI_UxProjectsImage3], ProjectName: "Sri Chaitanya Ortho & Eye", ProjectType: "Web Development / Website", 
    description: "We crafted a user-friendly website specifically designed for hospitals, offering seamless communication and appointment management to enhance patient care." },
    { images: [UI_UxProjectsImage4], ProjectName: "Ultimates Roofing", ProjectType: "Web Development / Website / Mobile App", 
    description: "We designed and developed a dynamic website for a roofing company, showcasing their expertise and services to potential clients with captivating visuals and user-friendly navigation." },
    { images: [DevelopmentProjectsImage1], ProjectName: "InnoWise LLC", ProjectType: "Web Development / Dashboard", 
    description: "Built dynamic and robust React dashboards for high-traffic applications, offering real-time insights and analytics." },
    { images: [DevelopmentProjectsImage2], ProjectName: "KMV Construction", ProjectType: "Web Development / Website", 
    description: "We provided comprehensive digital solutions for road construction projects, optimizing efficiency and enhancing project management processes." },
    { images: [UI_UxProjectsImage1], ProjectName: "KMV Construction", ProjectType: "Web Development / Website", 
    description: "We developed user-centric digital platforms tailored for road construction projects, facilitating seamless project monitoring and management." },
    { images: [DevelopmentProjectsImage3], ProjectName: "Quiz", ProjectType: "Web Development / Recruitment  Portal", 
    description: "Engineered an optimized quiz portal for recruitment, streamlining the hiring process and improving candidate assessments." },
]
const UI_UxProjects = [
    { images: [UI_UxProjectsImage3], ProjectName: "Sri Chaitanya Ortho & Eye", ProjectType: "UI/UX / Web Design / Prototype", 
    description: "Designed user-friendly interfaces and interactive experiences for the hospital website, prioritizing ease of navigation and accessibility to vital medical resources for visitors and patients alike" },
    { images: [UI_UxProjectsImage4], ProjectName: "Ultimates Roofing", ProjectType: "UI/UX / Web Design / Prototype / Mobile App Design", 
    description: "We developed intuitive UI/UX designs for Ultimates Roofing, optimizing user experience on both web and mobile platforms, enhancing brand visibility and usability." },
    { images: [UI_UxProjectsImage1], ProjectName: "KMV Construction", ProjectType: "UI/UX / Web Design / Prototype", 
    description: "Created user-centric digital designs and prototypes to enhance brand engagement. " }
]

const AllProjects = [
    { images: [CreativeDesignImage4], ProjectName: "Nimmagadda", ProjectType: "Creative Design / Branding / Logo", 
    description: "Crafted a distinctive design solution to enhance the hospital's identity and resonate with its audience." },
    { images: [CreativeDesignImage5, UI_UxProjectsImage4], ProjectName: "Ultimates Roofing", ProjectType: "Creative Design / UI/UX / Web Development / Mobile App ", 
    description: "We created a unique design that boosted the company’s visibility and helped it connect with its audience." },
    { images: [CreativeDesignImage3, UI_UxProjectsImage3], ProjectName: "Sri Chaitanya Ortho & Eye", ProjectType: "Creative Design / UI/UX / Web Development ", 
    description: "We designed and developed a responsive website for the hospital, offering intuitive navigation and comprehensive information to enhance patient experience and accessibility." },
    { images: [CreativeDesignImage6], ProjectName: "Stand Up Banner", ProjectType: "Creative Design / Banner Ads", 
    description: "Delivering high-quality stand-up banners to captivate attention and effectively convey the message or brand of clients." },
    { images: [CreativeDesignImage2], ProjectName: "Business Cards", ProjectType: "Creative Design / Branding / Business Cards", 
    description: "Crafting creative business cards tailored to elevate brand identity and leave a lasting impression." },
    { images: [CreativeDesignImage1], ProjectName: "Brochure", ProjectType: "Creative Design / Brochure", 
    description: "Producing captivating brochures to showcase offerings and engage the target audience effectively." },
    { images: [MarketingProjectsImage4, CreativeDesignImage7, MarketingProjectsImage2, MarketingProjectsImage3], ProjectName: "Jajimalli", ProjectType: "Creative Design / Banner Ads / Social Media Marketing ", 
    description: "Provided a range of creative design and branding services to strengthen their market position. " },
    { images: [UI_UxProjectsImage1, DevelopmentProjectsImage2], ProjectName: "KMV Construction", ProjectType: "UI/UX / Web Development", 
    description: "Created user-centric digital designs and prototypes to enhance brand engagement. " },
    { images: [DevelopmentProjectsImage1], ProjectName: "InnoWise LLC", ProjectType: "Web Development / Dashboard", 
    description: "Using React to build robust and dynamic dashboards for high-traffic applications like Innowise LLC, offering real-time insights and analytics." },
    { images: [DevelopmentProjectsImage3], ProjectName: "Quiz", ProjectType: "Web Development / Recruitment  Portal", 
    description: "Engineered an optimized quiz portal for recruitment, streamlining the hiring process and improving candidate assessments." },
    { images: [MarketingProjectsImage1, MarketingProjectsImage5], ProjectName: "Newsletter", ProjectType: "Digital Marketing / E-mail Marketing / Newsletter", 
    description: "Crafting newsletters, executing email campaigns, and implementing social media strategies to enhance brand visibility and engagement." },
    ...AnalysisProjects
]
function ProjectsScrolling(props) {

    const [ProjectTeamClicks, setProjectTeamClisks] = useState([1, 0, 0, 0, 0]);
    const [Projects, setProjects] = useState(AllProjects);

    const Ref = useRef(null);
    const scrolling = (index, value) => {
        const scrollPosition = Ref.current.scrollLeft;
        if (ProjectTeamClicks.indexOf(1) > index) {
            document.querySelector(".ProjectsScrolling-container-row").scrollTo({
                left: -value + scrollPosition,
                behavior: 'smooth'
            });

        }
        else if (ProjectTeamClicks.indexOf(1) < index) {
            document.querySelector(".ProjectsScrolling-container-row").scrollTo({
                left: value + scrollPosition,
                behavior: 'smooth'
            });

        }

    }

    const handlClicks = (index) => {
        var temp = [0, 0, 0, 0, 0];
        temp[index] = 1;
        setProjectTeamClisks([...temp]);
        switch (index) {
            case 0: setProjects(AllProjects); scrolling(index, 100); break;
            case 1: setProjects(CreativeDesignProjects); scrolling(index, 100); break;
            case 2: setProjects(UI_UxProjects); scrolling(index, 120); break;
            case 3: setProjects(DevelopmentProjects); scrolling(index, 150); break;
            case 4: setProjects(MarketingProjects); scrolling(index, 150); break;
            case 5: setProjects(AnalysisProjects); scrolling(index, 250); break;
            default: ;
        }

    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000, // Adjust the speed of transition
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1500,
        vertical: false, // Set to true for vertical scrolling

    };
    return (
        <Container fluid className='ProjectsScrolling-container'>
            <div className='ProjectsScrolling-container-row' ref={Ref}>
                <div className='ProjectsScrolling-container-row-col' onClick={() => { handlClicks(0) }} >
                    <div className={"ProjectsScrolling-teamName AllProject " + (ProjectTeamClicks[0] && ' ProjectsScrolling-teamName-active')} >All Projects</div>
                </div>
                <div className='ProjectsScrolling-container-row-col' onClick={() => { handlClicks(1) }} >
                    <div className={'ProjectsScrolling-teamName CreativeDesign ' + (ProjectTeamClicks[1] && 'ProjectsScrolling-teamName-active')} >Creative Design</div>
                </div>
                <div className='ProjectsScrolling-container-row-col' onClick={() => { handlClicks(2) }} >
                    <div className={'ProjectsScrolling-teamName UIUXDesign ' + (ProjectTeamClicks[2] && 'ProjectsScrolling-teamName-active')} >UI/UX Design</div>
                </div>
                <div className='ProjectsScrolling-container-row-col' onClick={() => { handlClicks(3) }} >
                    <div className={'ProjectsScrolling-teamName FullStackDevelopment ' + (ProjectTeamClicks[3] && 'ProjectsScrolling-teamName-active')} >Full Stack Development</div>
                </div>
                <div className='ProjectsScrolling-container-row-col ' onClick={() => { handlClicks(4) }} >
                    <div className={'ProjectsScrolling-teamName DigitalMarketing ' + (ProjectTeamClicks[4] && 'ProjectsScrolling-teamName-active')}>Digital Marketing</div>
                </div>
                <div className='ProjectsScrolling-container-row-col' onClick={() => { handlClicks(5) }} >
                    <div className={'ProjectsScrolling-teamName MarketingAnalysis ' + (ProjectTeamClicks[5] && 'ProjectsScrolling-teamName-active')} >Marketing Analysis</div>
                </div>
            </div>
            <Row>
                {
                    Projects.map((project, index) => {
                        if (project) {
                            return (
                                <div className='ProjectsScrolling-card-container' key={"images" + index}>
                                    {
                                        project.images.length !== 1 ? (
                                            <Slider {...settings}  >
                                                {(project.images).map((image, index) => {
                                                    return (
                                                        <img src={image} key={index + "image"} alt={"visheshProject" + { index } + ".png"} className='ProjectsScrolling-images' style={{ outline: "none", border: "none" }} />
                                                    )
                                                })}
                                            </Slider>) :
                                            <div>
                                                <img src={project.images[0]} alt={"visheshProject" + { index } + ".png"} className='ProjectsScrolling-images' />
                                            </div>
                                    }

                                    <div style={{ marginTop: "1.5rem" }}>
                                        <h5 className='services-heading service-team-heading'>{project.ProjectName}</h5>
                                    </div>
                                    <div style={{ marginTop: "0.5rem" }}>
                                        <p className='work-page-desc'>{project.description}</p>
                                        <p className='home-about-para' style={{ fontWeight: "bold" }}>{project.ProjectType}</p>
                                    </div>
                                </div>
                            )
                        }
                        else {
                            return (<></>)
                        }
                    })
                }

            </Row>
        </Container>
    );
}

export default ProjectsScrolling;