import React from 'react'
import './AboutUs.css'
import AboutUS from '../../../assets/HomePage/AboutUs.png'
import { useNavigate } from 'react-router-dom'

function AboutUs() {
    const navigate = useNavigate();
    return (
        <div className='home-about-section'>
            <div>
                <h2 className='home-about-heading home-about-section-css'>Who We Are</h2>
                <img src={AboutUS} className='about-us-img1' alt='visheshHome.png' />
                <p className='home-about-para home-about-section-css2'>For over seven years, Vishesh Country Cache has been leading the way in creating innovative solutions. We focus on meeting our client's needs and connecting with their audiences.</p>
                <p className='home-about-para'>We thrive on bringing bold, ambitious, and experimental ideas to life. But don't just take our word for it—explore our work and judge for yourself. Our mission is simple: to drive engagement, foster understanding, and inspire action through every digital experience we create.</p>
                <button onClick={() => { navigate('/about') }} className='about-us-btn home-about-section-css1'>About Us</button>
            </div>
            <div>
                <img src={AboutUS} className='about-us-img' alt='about-us' />
            </div>
        </div>
    )
}

export default AboutUs