import React, { useState, useEffect } from 'react';
import './ImagesSlider.css';
import Slider from "react-slick";
import TeamImage1 from '../../../assets/AboutPage/TeamImage1.avif';
import TeamImage2 from '../../../assets/AboutPage/TeamImage2.avif';
import TeamImage3 from '../../../assets/AboutPage/TeamImage3.avif';
import TeamImage4 from '../../../assets/AboutPage/TeamImage4.avif';
import TeamImage5 from '../../../assets/AboutPage/TeamImage5.avif';
import TeamImage6 from '../../../assets/AboutPage/TeamImage6.avif';
import TeamImage7 from '../../../assets/AboutPage/TeamImage7.avif';
import TeamImage8 from '../../../assets/AboutPage/TeamImage8.avif';
import TeamImage9 from '../../../assets/AboutPage/TeamImage9.avif';
import TeamImage10 from '../../../assets/AboutPage/TeamImage10.avif';
import TeamImage11 from '../../../assets/AboutPage/TeamImage11.avif';
import TeamImage12 from '../../../assets/AboutPage/TeamImage12.avif';
import TeamImage13 from '../../../assets/AboutPage/TeamImage13.avif';
import TeamImage14 from '../../../assets/AboutPage/TeamImage14.avif';

function ImagesSlider() {
  // Define initial autoplay speeds for each carousel
  const initialAutoplaySpeeds = [4000, 8000, 12000, 16000];

  // State variable to hold the current autoplay speeds
  const [autoplaySpeeds, setAutoplaySpeeds] = useState(initialAutoplaySpeeds);

  // Effect to update the autoplay speeds at specific intervals
  useEffect(() => {
    const timeouts = [];

    // After 7 seconds
    timeouts.push(setTimeout(() => {
      setAutoplaySpeeds(prevSpeeds => {
        const updatedSpeeds = [...prevSpeeds];
        updatedSpeeds[0] = 16000;
        return updatedSpeeds;
      });
    }, 7000));

    // After 12 seconds
    timeouts.push(setTimeout(() => {
      setAutoplaySpeeds(prevSpeeds => {
        const updatedSpeeds = [...prevSpeeds];
        updatedSpeeds[1] = 16000;
        return updatedSpeeds;
      });
    }, 10000));

    // After 17 seconds
    timeouts.push(setTimeout(() => {
      setAutoplaySpeeds(prevSpeeds => {
        const updatedSpeeds = [...prevSpeeds];
        updatedSpeeds[2] = 16000;
        return updatedSpeeds;
      });
    }, 14000));

    // After 22 seconds
    timeouts.push(setTimeout(() => {
      setAutoplaySpeeds(prevSpeeds => {
        const updatedSpeeds = [...prevSpeeds];
        updatedSpeeds[3] = 16000;
        return updatedSpeeds;
      });
    }, 18000));

    // Clear timeouts on component unmount
    return () => {
      timeouts.forEach(timeout => clearTimeout(timeout));
    };
  }, []);

  return (
    <div className='about-container'>
      <div>
        <Slider pauseOnHover={false} dots={false} infinite={true} speed={1000} slidesToShow={1} slidesToScroll={1} autoplay={true} autoplaySpeed={autoplaySpeeds[0]}>
          <div>
            <img src={TeamImage1} className='team-image' alt='vishesh-team-members' />
          </div>
          <div>
            <img src={TeamImage2} className='team-image' alt='vishesh-team-members' />
          </div>
          <div>
            <img src={TeamImage3} className='team-image' alt='vishesh-team-members' />
          </div>
        </Slider>
      </div>

      <div className='about-image-carousel2'>
        <div className='about-image-carousel-section1'>
          <div>
            <Slider pauseOnHover={false} dots={false} infinite={true} speed={1000} slidesToShow={1} slidesToScroll={1} autoplay={true} autoplaySpeed={autoplaySpeeds[1]}>
              <div>
                <img src={TeamImage4} className='team-image' alt='vishesh-team-members' />
              </div>
              <div>
                <img src={TeamImage5} className='team-image' alt='vishesh-team-members' />
              </div>
              <div>
                <img src={TeamImage6} className='team-image' alt='vishesh-team-members' />
              </div>
            </Slider>
          </div>
          <div>
            <Slider pauseOnHover={false} dots={false} infinite={true} speed={1000} slidesToShow={1} slidesToScroll={1} autoplay={true} autoplaySpeed={autoplaySpeeds[2]}>
              <div>
                <img src={TeamImage7} className='team-image' alt='vishesh-team-members' />
              </div>
              <div>
                <img src={TeamImage8} className='team-image' alt='vishesh-team-members' />
              </div>
              <div>
                <img src={TeamImage9} className='team-image' alt='vishesh-team-members' />
              </div>
              <div>
                <img src={TeamImage10} className='team-image' alt='vishesh-team-members' />
              </div>
            </Slider>
          </div>
        </div>

        <div className='about-image-carousel-section2'>
          <Slider pauseOnHover={false} dots={false} infinite={true} speed={1000} slidesToShow={1} slidesToScroll={1} autoplay={true} autoplaySpeed={autoplaySpeeds[3]}>
            <div>
              <img src={TeamImage11} className='team-image' alt='vishesh-team-members' />
            </div>
            <div>
              <img src={TeamImage12} className='team-image' alt='vishesh-team-members' />
            </div>
            <div>
              <img src={TeamImage13} className='team-image' alt='vishesh-team-members' />
            </div>
            <div>
              <img src={TeamImage14} className='team-image' alt='vishesh-team-members' />
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default ImagesSlider;
